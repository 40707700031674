function End() {
    return (
        <>
        <div class="flex flex-col items-center justify-center bg-yellow-200 w-screen h-screen">
            <div class="flex flex-col max-w-[1000px] w-screen h-screen items-center justify-center m-12 bg-white">
                <img class="w-[50%] mb-8" src="images/결제완료페이지.jpg"/>
                <div class="font-Pretendard text-[30px] text-center">
                    폼이 종료되었습니다
                    <br/>다음에 또 만나요!
                    <br/><br/><p class="text-[20px]">혹시 문의사항이 있다면 lazyguineapig1@gmail.com으로 연락주세요</p>
                </div>
            </div>
        </div>
        </>
    )
}

export default End;